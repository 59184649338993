<template>
  <div class="instructions container is-max-desktop">
    <h1 class="i__title"><span style="color: #D7533C;">Каспи перевод</span> аркылы жиберу</h1>
    <div class="i__section">
      <div class="i__number">1</div>
      <div class="i__content">
        <p>
          Введите наш номер телефона
          <br>
          <span class="i__red-underline">{{ phone }}</span>
        </p>

      </div>

    </div>
    <div class="i__section">
      <div class="i__number">2</div>
      <div class="i__content">
        <p>
          Введите сумму
          <span class="i__red-underline"> 60 000₸ </span> 
          вашего пакета
        </p>
        <img 
          :src="require('@/assets/instructions/2.png')" 
          alt=""
          class="mt-4"
        >
      </div>
    </div>

    <div class="i__section">
      <div class="i__number">3</div>
      <div class="i__content">
        <p class="mb-1">Введите ниже в комментах ваш номер телефона с которого вы зарегистрированы в Joo.kz
        </p>
        <div 
          v-if="exists === '0'"
          class="i__danger">
          <p>Ваш номер не зарегистрирован, просим зарегистрироваться в приложении или на сайте Joo.kz
            <br>Скачать приложение можете <a href="#">здесь</a>
            <br>Войти на сайт можете <a href="#">здесь</a>
          </p>
        </div>
        <p style="color: rgb(158,155,152);">Нужно указать именно тот номер телефона с которого вы зарегистрированы в Joo.kz</p>
        <img 
          :src="require('@/assets/instructions/3.png')" 
          alt=""
          class="mt-4"
        >
      </div>

    </div>
    <div class="i__section">
      <div class="i__number">4</div>
      <div class="i__content">
        <p class="mb-4">Нажмите на кнопку Перевести</p>
        <img 
          :src="require('@/assets/instructions/1.png')" 
          alt=""
        >
        <img 
          :src="require('@/assets/instructions/4.png')" 
          alt=""
        >
      </div>

    </div>
    <div class="i__section">
      <div class="i__number">5</div>
      <div class="i__content">
        <p>После оплаты, отправьте чек на 
          <span class="i__red-underline">Ватсап +7 777 864 7474</span>
        </p>
      </div>

    </div>
    <!-- <h1></h1>
    <h1>{{ exists }}</h1> -->
  </div>
</template>

<script>
export default {
  props: ['phone', 'exists']
}
</script>

<style lang="scss">
.instructions{
  padding: 1.5rem;
  // font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  // display: flex;
  // align-items: center;
  // text-align: center;
  letter-spacing: 0.02em;
  
  img {
    display: block;
  }
  .i__title{
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
  .i__section{
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: nowrap;
  }
  .i__number{
    position: relative;
    flex-shrink: 0;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    
    text-align: center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border: 1.4px solid #2F2C2B;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
    }
  }
  .i__content {
    margin-left: 1.25rem;
  }
  .i__danger {
    background: rgba(215,83,60,0.1);
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 0.25rem;
    p {
      font-style: normal;
      font-weight: normal;
      color: #D13D23;
    }
  }
  .i__red-underline{
    color: #FF845A;
    text-decoration: underline;
    font-weight: 600;
  }
}
</style>